.mainLayout{
    .layoutSider{
        overflow: auto;
        height: 100vh;
        position: fixed;
        left: 0;
        .logoBox{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 15px;
            .companyName{
                font-size: large;
                font-weight: 500;
                color: white;                
            }
            .description{
                color: white
            }
        }
    }
    .layoutRight{
        margin-left: 260px;
        background: initial;
    }    
    .layoutHeader{
        background: #fff;
        padding: 0 35px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title{
            font-size: x-large;
            font-weight: 500;            
        }
        .loginRow{
            font-size: medium;
            .login{
                font-weight: 500;
            }
            .logout{
                margin-left: 15px;
            }
        }
    }
    .layoutContent{
        margin: 0;
        padding: 12px;
        overflow: initial;
        background-color: #f5f5f5ff;
    }
}

body{
    background-color: #f5f5f5ff;
}