.userPage{
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .ant-card-body{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .inpLine{
        display: flex;
        flex-direction: row;

        >span {
            width: 190px;
            margin-right: 5px;
        }
    }
}