.billList{
    .ant-card-body{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .search{
    }
    .pagination{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .selected{
        background-color: #E6F7FFAA;
    }
}