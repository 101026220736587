.emails{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    .header{
        font-size: large;
        font-weight: 500;
    }

    .row{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .row + .row{
        margin-top: 5px;
    }
    .addBtn{
        margin-top: 5px;
    }
}