.phoneCallEditor{
    .editorTitle{
        display: flex;
        justify-content: space-between;
    }
    .ant-card-body{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    
    .btnLine{
        margin-top: 10px;
    }

    .mainTab{
        .inpLine{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
    
            .label{
                display: inline-block;
                min-width: 150px;
                max-width: 150px;
                text-align: end;
                margin-right: 5px;
            }
        }
        
        .inpLine + .inpLine{
            margin-top: 10px;
        }
    }
}