.billEditor{
    .editorTitle{
        display: flex;
        justify-content: space-between;
    }
    .ant-card-body{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    
    .btnLine{
        margin-top: 10px;
    }

    .mainTab{
        .inpLine{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
    
            .label{
                display: inline-block;
                min-width: 150px;
                max-width: 150px;
                text-align: end;
                margin-right: 5px;
            }
        }
        
        .inpLine + .inpLine{
            margin-top: 10px;
        }
    }

    .posTab{
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .posEditor{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            border-top: 1px solid #1890ff;
            border-right: 1px solid #1890ff;

            .cell{
                padding: 8px 4px;
                border-left: 1px solid #1890ff;
                border-bottom: 1px solid #1890ff;
            }
            .cell:not(.head){
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .head{
                background-color: #1890ff;
                color: white;
            }
        }
    }
}