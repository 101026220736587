.shopEditor{

    .editorTitle{
        display: flex;
        justify-content: space-between;
    }

    .ant-card-body{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .inpLine{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        span{
            display: inline-block;
            min-width: 80px;
            max-width: 80px;
            text-align: end;
            margin-right: 5px;
        }
    }
    
    .inpLine + .inpLine{
        margin-top: 10px;
    }

    .btnLine{
        margin-top: 10px;

        button + button{
            margin-left: 5px;
        }
    }
}