.yaMap{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .mapContainer{
        width: 100%;
        height: 500px;
    }
}