.loginPage{
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loginBlock{
        width: 400px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        .ant-card-body{
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
        }
    }
    .info{
        display: inline-block;
        width: 100%;
        font-size: x-large;
        font-weight: 500;
        text-align: center;
    }
}