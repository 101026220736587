.citiesPage{

    display: flex;
    flex-direction: column;
    align-items: stretch;

    .citiesListBlock{
        .ant-card-body{
            padding: 10px;
        }
    }

    .citiesList{
        display: flex;
        flex-direction: column;
        
        max-height: 250px;
        overflow-x: hidden;
        overflow-y: auto;

        .item{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            min-height: 50px;
            max-height: 50px;

            .name{
                margin-left: 15px;
                font-size: large;
            }

            .controls .ant-btn{
                margin: 0 10px;
            }
        }
        .selectedItem{
            background-color: #BAE7FF !important;
        }
        .item:hover{
            background-color: whitesmoke;
        }
        .item + .item{
            border-top: 1px solid whitesmoke;
        }
    }

    .cityEditor{
        margin-top: 10px;
        .ant-card-body{
            padding: 10px;
        }
    }
}